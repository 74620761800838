export const apiConfig = {
    url: "https://api.mesto.number396.nomoredomains.monster",
    headers: {
        "Content-Type": "application/json",
    },
};

export const apiAuthConfig = {
    url: "https://api.mesto.number396.nomoredomains.monster/",
    headers: {
        "Content-Type": "application/json"
    }
}
